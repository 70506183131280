import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/1.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} />
          {/* <p className="cname">Art Tech</p> */}
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="#home">Home</a></p>
          <p><a href="#wgpt3">About ArtTech</a></p>
          <p><a href="#possibility">Mission & Vision</a></p>
          <p><a href="#features">Life Cycle</a></p>
          <p><a href="#blog">Services</a></p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">

        <button type="button">Book a Call</button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><a href="#home">Home</a></p>
            <p><a href="#wgpt3">About ArtTech</a></p>
            <p><a href="#possibility">Mission & Vision</a></p>
            <p><a href="#features">Life Cycle</a></p>
            <p><a href="#blog">Services</a></p>
          </div>
          <div className="gpt3__navbar-menu_container-links-sign">

            <button type="button">Book a Call</button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
