import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="About ArtTech"
        text="Incepted in 2019, ArtTech is a leading Development & Design expert company providing superior
        Software, Web, Mobile and Creative Designing solutions and services to companies globally. Having a strong Technology Development and Innovation center in Pakistan BT has made it’s mark as a well established and sound company driven by the industry’s highly professional and trained individuals and is surely headed in becoming a leading organization which facilitates, enhances and provides measurable business value to customers through most effective uses of technology and Resources to organizations globally."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>

    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Services" text="Mobile & Web Development, UI/UX Design, Internet of things, Product Photography, It consultency" />
      <Feature title="Technologies" text="C++, Python, Loopback 4, React, Qt Quick, MongoDb, MySQL" />
      <Feature title="Industries" text="Construction, HealthCare, Travel & Hospitality, Retail & Ecommerce" />
    </div>
  </div>
);

export default WhatGPT3;
