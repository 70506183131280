import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Planning and Designing',
    text: 'We collect all the relevant information from the customer to develop custom software development solutions as per their expectation. The system and documents are prepared as per the requirement specifications. This helps us define overall system architecture and technology stack.',
  },
  {
    title: 'Defining and Building',
    text: 'Once the requirement analysis phase is completed, the next step is to define and document software needs. Developers start to build the entire system by writing code using the chosen programming language, techniques, and methodologies.',
  },
  {
    title: 'Testing and Deployment',
    text: 'Evaluating the quality of software with the aim of finding and fixing defects. The final software is released and checked for deployment issues, if any.',
  },
  {
    title: 'Maintenance',
    text: 'According to the service level agreement, we ensure that needs continue to be met and that the system continues to perform as per the specification mentioned in the first phase.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">CUSTOM SOFTWARE DEVELOPMENT LIFECYCLE</h1>
      <p>We employ best practice processes and development methodologies as a foundation for rapid building of cutting-edge technology solutions in a structured and methodical way.</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
