import React from 'react';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">WHO WE ARE</h1>
      <p>We are a digital transformation consultancy and software development company that provides cutting edge engineering solutions, helping Fortune for companies and enterprise clients untangle complex issues that always emerge during their digital evolution journey. Since 2019 we have been a visionary and a reliable software engineering partner for world-class brands.</p>
      <div className="gpt3__header-content__input">
        <button type="button">Get in Touch</button>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
