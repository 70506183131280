import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">OUR CORE CUSTOM <br /> SOFTWARE DEVELOPMENT SERVICES</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article
          imgUrl={blog01}
          title="OUR HIGH-END MOBILE APP DEVELOPMENT SERVICES"
          text="We offer a full cycle of application design,
          integration and management services.
          Whether it is a consumer oriented app or a transformative enterprise-class solution,
          the company leads the entire mobile app development process from ideation and concept to delivery,
          and to ongoing ongoing support. We deliver efficient approaches and high-end technologies that empower your software and help you create extra value for your clients"
        />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article
          imgUrl={blog02}
          title="UI AND UX DESIGN SERVICES"
          text="Want to build your product with a team that establishes a clear design process, meets deadlines, and delivers a spot-on end result? Turn to ArtTech’s UI and UX services. Our design team is a small design studio within a large software company that will help you build an engaging product easily and quickly."
        />
        <Article
          imgUrl={blog03}
          title="IT CONSULTING SERVICES"
          text="Use the expertise and deep tech background of the best minds at ArtTech to create a comprehensive IT strategy for a digital and technological transformation of your organization that goes in line with your business objectives. Our strategic IT consulting will help you automate and digitalise operations, optimise the software portfolio, and implement the latest technologies."
        />
        <Article
          imgUrl={blog04}
          title="PRODUCT PHOTOGRAPHY"
          text="We are a team of professionals who are experts in Electronic Commerce who will help you determine the specific needs of your online store and advise you on any type of doubt you have. We adapt to the needs of each online store, to be able to photograph few products or an extensive catalog of articles. "
        />
        <Article
          imgUrl={blog05}
          title="IOT DEVELOPMENT SERVICES"
          text="You need reliability when it comes to IoT technology. ArtTech keeps your company connected with the IoT software solutions we create. Stay ahead of the competition with flexible IoT development services created by a team that lives and breathes IoT."
        />
      </div>
    </div>
  </div>
);

export default Blog;
